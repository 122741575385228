import BossModel from "@/models/BossModel";
import Customer from "@/models/Customer/Customer";
import User from "@/models/User/User";
import Product from "@/models/Agent/Product/Product";
import Message from "@/models/Agent/Message/Message";
import Proposal from "@/models/Proposal/Proposal";

export default class Dossier extends BossModel {

    /**
     *
     * @type {string}
     */
    static apiUrl = 'dossier';

    /**
     *
     * @type {string}
     */
    static entity = 'dossiers';

    static fields() {
        return {
            id: this.attr(null),
            customer_id: this.attr(null),
            public_id: this.attr(null),
            user_id: this.attr(null),
            trip_ends_at: this.attr(null),
            service_label_cid: this.number(null),
            customer: this.belongsTo(Customer, 'customer_id'),
            user: this.belongsTo(User, 'user_id'),
            proposals: this.hasMany(Proposal, 'dossier_id'),
            lastEmployeeAcceptedProposal: this.hasOne(Proposal, 'dossier_id'),
            agentProducts: this.hasMany(Product, 'dossier_id'),
            agentMessages: this.hasMany(Message, 'dossier_id'),
        }
    }

    IsCancelled() {
        return this.cancelled_at !== null
    }

    IsCancellationConfirmed() {
        return this.is_cancellation_confirmed;
    }

     static async timeline(agentHash, dossierHash) {
        return this.api().action('timeline/' + agentHash + '/' + dossierHash).getValue();
    }
}
