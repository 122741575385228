import BossModel from "@/models/BossModel";
import LocalStorageService from "@/utils/Services/LocalStorageService";
import Auth from "@/models/Agent/Auth/Auth";

export default class Agent extends BossModel {

    static MAIL_NOTIFY = 1;
    static API_NOTIFY = 2;
    static NO_NOTIFY = 3;

    /**
     *
     * @type {string}
     */
    static apiUrl = 'agent';

    /**
     *
     * @type {string}
     */
    static entity = 'agents';

    static fields() {
		return {
			id: this.number(null).nullable(),
            public_id: this.attr(null),
			name: this.attr(null),
			email: this.attr(null),
			contact: this.attr(null),
			notify_cid: this.number(null),
		}
	}

    static loggedIn() {
        return this.query().find(Auth.getAgentId());
    }

    static fetchLoggedIn() {
        return this.api().action('current').getSingle().then(agent => {
            Auth.setAgentId(agent.id);

            return true;
        }).catch(() => {
            Auth.removeAgentId();
            LocalStorageService.remove('token');

            return false;
        });
    }

    static async isSellingPlatinum() {
        let isSellingPlatinum = false;
        await this.api().action('is-selling-platinum').getSingle(false).then(result => {
            isSellingPlatinum = result.is_selling_platinum;
        });

        return isSellingPlatinum;
    }

    static login(email, password) {
        return this.api().action('auth/login').create({
            'email': email,
            'password': password,
        }, false).then(result => {
            LocalStorageService.set('token', result.token);
        });
    }

    async logout() {
        await this.constructor.api().action('auth/logout').getSingle(false);
        LocalStorageService.remove('token');
        Auth.removeAgentId();

        return true;
    }

    static requestPassword(email) {
        return this.api().action('password-reset').create({
            'email': email
        }, false);
    }

    static savePassword(token, password) {
        return this.api().action('password-reset/password').update({
            'token': token,
            'password': password
        }, false);
    }
}
